@use 'abstracts/functions';
@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/base' as base-mixins;
@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/layout';

$accordion-mw: functions.break(60);

// Remove details marker from polyfill (IE11)
summary::before {
  display: none;
}

.m-accordion,
.m-accordion-small,
.m-document-accordion {
  svg {
    fill: currentColor;
  }

  summary {
    @include appearance.focus-default;
    display: block; // Removes details marker in Firefox

    &::-webkit-details-marker {
      display: none;
    }
  }

  &__summary {
    display: flex;
    align-items: center;
    color: functions.palette(dark-blue);
    cursor: pointer;
  }

  &[open] &__icon--v-path {
    fill: transparent;
  }
}

.m-accordion {
  @include layout.container-primary;

  // nested inside a Group block
  .m-group &,
  .m-two-columns &,
  .m-main-and-sidebar & {
    padding-inline: 0;
  }

  &__icon {
    inline-size: 25px;
    block-size: 25px;
    position: relative;
    top: 3px;
    color: functions.palette(dark-blue);
  }

  &__summary {
    align-items: baseline;
    border-block-start: 1px solid rgba(#333, 0.25);
    border-block-end: 1px solid rgba(#333, 0.25);

    .m-accordion + .m-accordion & {
      border-block-start: none;
    }

    .m-accordion[open] & {
      border-block-end: none;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: functions.font-weight(book);

    .m-content-rte & {
      flex-grow: 1;
      padding-block: functions.padding(x-small);
      padding-inline-end: functions.padding(x-small);
    }
  }

  &__body {
    padding-block-end: functions.padding(x-small);

    .m-main-and-sidebar & {
      padding-block-end: 0;
    }

    .m-accordion[open] & {
      border-block-end: 1px solid rgba(#333, 0.25);
    }

    p,
    h4,
    h5 {
      @include base-mixins.fluid-scale(line-height, 18px, 28px);
    }

    h4,
    h5 {
      padding-block-end: 1em;
      color: functions.palette(dark-blue);

      @media (min-width: $accordion-mw) {
        font-weight: functions.font-weight(book);

        &:not(:first-child) {
          @include base-mixins.fluid-scale(
            padding-block-start,
            functions.padding(x-small),
            functions.padding(large)
          );
        }
      }
    }
  }
}

.m-accordion-small {
  &__title {
    @include base-mixins.fluid-scale(
      margin-block margin-inline-end,
      functions.padding(x-x-small),
      functions.padding(x-small)
    );
    @include typography.copy-primary;
    flex-grow: 0;
    font-weight: functions.font-weight(bold);
  }

  &__body {
    p:last-child {
      padding-block-end: 0;
    }
  }
}
